import React, { useEffect } from 'react';
import { withRouter } from 'next/router';

// IF 404 page is found, redirect to Homepage
function ErrorPage(props) {
    useEffect(() => {
        props.router.push('/');
    }, []);
    
    return null
}

export default withRouter(ErrorPage);